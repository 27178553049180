---
layout: changelog
title: Announcing the Appwrite daily.dev Squad
date: 2024-07-23
cover: /images/changelog/2024-07-23.png
---

We’re excited to announce our new daily.dev public Squad, which you, too, can join!

We already share all of our blogs on the [daily.dev](http://daily.dev) platform, and now we also have a moderated Squad. This will make it easier for you to engage with our content and also share your content with the community.

{% arrow_link href="/blog/post/announcing-appwrite-daily-dot-dev-squad" %}
Read the announcement to learn more
{% /arrow_link %}
