---
layout: changelog
title: Appwrite API references now display the rate limits per endpoint.
date: 2024-03-07
cover: /images/changelog/2024-03-07.png
---

Find them in [references documentation](/docs/references).

{% arrow_link href="/docs/advanced/platform/rate-limits" %}
Learn about Rate limits
{% /arrow_link %}
