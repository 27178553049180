---
layout: changelog
title: Updates to AI assistant
date: 2024-01-18
cover: /images/changelog/2024-01-18.png
---

We've updated our AI assistant. It's now trained on the latest available documentation and examples. Additionally, Appwrite Assistant now displays a list of relevant documentation pages with each query.

{% arrow_link href="https://github.com/appwrite/assistant" %}
Learn more about Appwrite Assistant on GitHub
{% /arrow_link %}